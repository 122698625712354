import { render, staticRenderFns } from "./FooterMain.vue?vue&type=template&id=58ac3024&"
import script from "./FooterMain.vue?vue&type=script&lang=js&"
export * from "./FooterMain.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UtilitiesSocialMedia: require('/codebuild/output/src688422052/src/student-frontend/components/utilities/SocialMedia.vue').default})
